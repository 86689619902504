import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Nillable } from '@core/utils/nil/nillable';
import { UserContext } from '@core/auth/models/user-context';

@Injectable({
  providedIn: 'root',
})
export class UserContextStorage {
  private readonly userContextSource$ = new BehaviorSubject<
    Nillable<UserContext>
  >(null);

  clear(): void {
    this.userContextSource$.next(null);
  }

  fetchSync(): Nillable<UserContext> {
    return this.userContextSource$.getValue();
  }

  fetchAsync(): Observable<Nillable<UserContext>> {
    return this.userContextSource$.asObservable();
  }

  store(context: UserContext): void {
    this.userContextSource$.next(context);
  }
}
